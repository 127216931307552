.tableWrapper {
    overflow-x: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.tableWrapper > div {
    overflow-y: auto;
    max-height: calc(100vh - 50px); /* убедитесь, что высота расчета правильная */
    margin-top: 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Делаем таблицу фиксированной по ширине */
}

thead th {
    position: sticky;
    top: 0;
    background-color: #4d4d59;
    z-index: 2;
}

input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background-color: #fff;
    color: #666;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 5px;
    font-size: 16px;
}

/* Принудительно отобразить значок календаря в Chrome */
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(30%);
    opacity: 1;
    display: block;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23666" d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm-7-9h5v3h-5zm-6 0h5v3H6zm0 4h5v3H6zm6 0h5v3h-5z"/></svg>')
        no-repeat center;
    cursor: pointer;
    width: 24px;
    height: 24px;
}
